import React, { useEffect } from 'react'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

const Adsense = ({ type }) => {
  const {
    adClient,
    adSlotDisplaySidebarBottom,
    adSlotDisplayInArticleLeft,
    adSlotDisplayInArticleRight,
    adSlotInArticle,
    adSlotDisplayHome,
    adSlotMultiplex,
    adSlotDisplayBanner,
  } = useSiteMetadata()

  useEffect(() => {
    if (process.env.NODE_ENV === 'production')
      try {
        ;(window.adsbygoogle = window.adsbygoogle || []).push({})
        console.log('Advertise is pushed')
      } catch (e) {
        console.error('AdvertiseError', e)
      }
  })

  const adsenseStyle = {
    background: 'lightgray',
    color: 'black',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 8,
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
  }

  if (process.env.NODE_ENV !== 'production')
    return <div style={adsenseStyle}>광고 표시 영역</div>

  const adsenseTypes = {
    'display-banner': (
      <div
        className="ads-banner"
        style={{
          background: 'var(--clr-base)',
          display: 'flex',
          justifyContent: 'center',
          padding: '1.5rem 0',
        }}
      >
        <ins
          className="adsbygoogle"
          style={{
            display: 'block',
            width: '728px',
            height: '90px',
            textAlign: 'center',
          }}
          data-ad-format="horizontal"
          data-full-width-responsive="true"
          data-ad-client={adClient}
          data-ad-slot={adSlotDisplayBanner}
        ></ins>
      </div>
    ),
    'display-home': (
      <ins
        className="adsbygoogle"
        style={{ display: 'block', textAlign: 'center' }}
        data-ad-format="vertical"
        data-full-width-responsive="true"
        data-ad-client={adClient}
        data-ad-slot={adSlotDisplayHome}
      ></ins>
    ),
    'display-sidebar-bottom': (
      <ins
        className="adsbygoogle"
        style={{ display: 'block', marginTop: '1.5rem' }}
        data-ad-format="rectangle"
        data-full-width-responsive="true"
        data-ad-client={adClient}
        data-ad-slot={adSlotDisplaySidebarBottom}
      ></ins>
    ),
    'display-in-article-left': (
      <ins
        className="adsbygoogle square"
        data-ad-client={adClient}
        data-ad-slot={adSlotDisplayInArticleLeft}
      ></ins>
    ),
    'display-in-article-right': (
      <ins
        className="adsbygoogle square pc-only"
        data-ad-client={adClient}
        data-ad-slot={adSlotDisplayInArticleRight}
      ></ins>
    ),
    'in-article': (
      <div style={{ padding: 8, margin: '1.25rem auto 2rem auto' }}>
        <ins
          className="adsbygoogle"
          style={{ display: 'block', textAlign: 'center' }}
          data-ad-layout="in-article"
          data-ad-format="fluid"
          data-full-width-responsive="true"
          data-ad-client={adClient}
          data-ad-slot={adSlotInArticle}
        ></ins>
      </div>
    ),
    multiplex: (
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-format="autorelaxed"
        data-ad-client={adClient}
        data-ad-slot={adSlotMultiplex}
      ></ins>
    ),
  }

  return adsenseTypes[type] || null
}

export default Adsense
