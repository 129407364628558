import React from 'react'

const DefiningTerm = ({ children }) => {
  return (
    <dt
      style={{
        fontWeight: 'bolder',
      }}
    >
      {children}
    </dt>
  )
}

export default DefiningTerm
