import React, { useEffect, useState } from 'react'
import { BiArrowFromBottom } from 'react-icons/bi'
import newProjectIcon from '../../static/new-project-icon.png'
import styled from 'styled-components'

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    const scrolled = window.scrollY || document.documentElement.scrollTop
    if (scrolled > 800) {
      setIsVisible(true)
    } else if (scrolled <= 800) {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)

    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  return (
    <Wrapper>
      {/* <button
        type="button"
        className="btn bottom move-to-new-project"
        style={{ display: isVisible ? 'inline' : 'none' }}
      >
        <a href="https://earthquakearchive.kr">
          <span>new</span>
          <img
            src={newProjectIcon}
            alt="new-project-icon"
            className="icon"
            aria-hidden="true"
          />
        </a>
      </button> */}
      <button
        type="button"
        className="btn bottom scroll-to-top"
        onClick={scrollToTop}
        style={{ display: isVisible ? 'inline' : 'none' }}
      >
        <BiArrowFromBottom className="icon" aria-hidden="true" />
      </button>
    </Wrapper>
  )
}

export default ScrollToTop

const Wrapper = styled.section`
  .bottom {
    position: fixed;
    right: 3px;
    width: 50px;
    z-index: 99;
    height: 50px;
    border: none;
    outline: none;
    border-radius: 50%;
    color: var(--clr-base);
    background: transparent;
    font-size: 1.75rem;
    box-shadow: none;
    padding: 0;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
      background: transparent;
    }
  }
  .scroll-to-top {
    bottom: 20px;
  }
  .move-to-new-project {
    bottom: 60px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a svg {
      color: var(--clr-hyperlink);
    }
    span {
      font-size: 0.5rem;
      position: absolute;
      color: var(--clr-hyperlink);
      margin-bottom: 3rem;
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
  @media (min-width: 804px) {
    .btn {
      font-size: 2rem;
    }
    .move-to-new-project {
      bottom: 70px;
    }
  }
`
