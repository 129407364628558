import React from 'react'
import Title from './Title'
import CategoriesTags from '../CategoriesTags'
import styled from 'styled-components'

const BannerCategoriesTags = () => {
  return (
    <Wrapper>
      <Title title="태그"></Title>
      <CategoriesTags />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .tag {
    font-size: 0.7rem;
    font-weight: 400;
    color: var(--clr-content-semiemphasis);
    text-transform: capitalize;
    display: block;
    padding: 0.25rem 0.6rem;
    letter-spacing: var(--spacing);
    transition: var(--transition);
    border-radius: var(--radius);
    line-height: 1.25;
    margin-left: 0.75rem;
  }
  .tag:hover {
    background: var(--clr-hover-border-background-dark);
  }
  .tagsCategories {
    display: grid;
    grid-template-columns: 1fr;
  }
`
export default BannerCategoriesTags
