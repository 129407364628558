import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CaptionedImage, InternalLink } from "../../../../src/components";
import * as React from 'react';
export default {
  Link,
  GatsbyImage,
  getImage,
  CaptionedImage,
  InternalLink,
  React
};