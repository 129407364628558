import React from 'react'
import TagsList from './TagsList'
import styled from 'styled-components'

const CategoriesTags = () => {
  return (
    <Wrapper>
      <summary style={{marginLeft: '1rem'}}> 리스트 펼쳐보기</summary>
      <TagsList className="tag" />
    </Wrapper>
  )
}

const Wrapper = styled.details`
  list-style: none;
  summary {
    list-style-type: '+';
  }
  ::marker {
    none
  }
`

export default CategoriesTags

