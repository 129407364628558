import React from 'react'
import { Link } from 'gatsby'
import replaceSpaceWithDash from '../utils/replaceSpaceWithDash'
import translateTitle from '../utils/translateTitle'
import styled from 'styled-components'
import Title from './Banner/Title'

const CategoryList = ({ bannerTitle, group, subcategoryInfo }) => {

  function renderPostsCount(filteredObject) {
    return <>{filteredObject.totalCount}</>
  }

  function renderItems(categoryWithHyphen, subcategoryList) {
    return (
      <ul>
        {subcategoryList.map(subcategory => {
          const subcategoryWithHyphen = replaceSpaceWithDash(subcategory)
          const subcategoryInKorean = translateTitle(subcategory)
          const postCount = renderPostsCount(
            subcategoryInfo.group.find(item => item.fieldValue === subcategory)
          )
          return (
            <li key={subcategory} className="sub-category">
              <Link
                to={`/blog/category/${categoryWithHyphen}/${subcategoryWithHyphen}/`}
                key={subcategory}
              >
                {subcategoryInKorean}
                <span>{postCount}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <Wrapper>
      <Title title={bannerTitle.bannerTitle} />
      {group.map(item => {
        const category = item.fieldValue
        const categoryWithHyphen = replaceSpaceWithDash(item.fieldValue)
        const postsTotalCount = item.totalCount
        const subcategoriesList = item.distinct

        const categoryInKorean = translateTitle(category)
        return (
          <li key={categoryWithHyphen} className="category">
            <Link to={`/blog/category/${categoryWithHyphen}/`}>
              {categoryInKorean}
              <span className="category-counts">{postsTotalCount}</span>
            </Link>
            {item && renderItems(categoryWithHyphen, subcategoriesList)}
          </li>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  .category,
  .sub-category {
    font-size: 1rem;
    font-weight: 700;
    text-transform: capitalize;
    padding: 0.25rem 0 0.25rem 0.6rem;
    letter-spacing: var(--spacing);
    transition: var(--transition);
    border-radius: var(--radius);
    .category-counts {
      background: var(--clr-base-decoration);
      color: var(--clr-white);
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.7;
    }
    a {
      color: var(--clr-content);
      display: flex;
      justify-content: space-between;
    }
    .a:hover {
      background: var(--clr-hover-border-background);
    }
  }
  .sub-category {
    font-size: 0.85rem;
    font-weight: 400;
    margin-right: 0.4rem;
  }
`

export default CategoryList
