import React from "react"

const Code = ({ children }) => {
  return (
    <code
      style={{
        background: 'var(--clr-code)',
        color: 'var(--clr-content)',
        fontSize: '14px',
        padding: '0.25rem 0.5rem',
        borderRadius: 'var(--radius)',
        fontWeight: 600,
        fontFamily: 'var(--ff-default-vs-code)',
      }}
    >
      {children}
    </code>
  )
}

export default Code
