import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { CategoryList } from '../../components'

const query = graphql`
  query postsCategory {
    categoryInfo: allMdx(
      filter: {
        frontmatter: { division: { eq: "post" }, published: { eq: true } }
      }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        distinct(field: frontmatter___subcategory)
      }
    }
    subcategoryInfo: allMdx(
      filter: {
        frontmatter: { division: { eq: "post" }, published: { eq: true } }
      }
    ) {
      group(field: frontmatter___subcategory) {
        fieldValue
        totalCount
        distinct(field: frontmatter___subcategory)
      }
    }
  }
`
const CategoriesPosts = bannerTitle => {
  const {
    categoryInfo: { group },
    subcategoryInfo,
  } = useStaticQuery(query)

  return (
    <CategoryList
      bannerTitle={bannerTitle}
      group={group}
      subcategoryInfo={subcategoryInfo}
    />
  )
}

CategoriesPosts.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
}

export default CategoriesPosts
