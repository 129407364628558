import React from 'react'
import styled from 'styled-components'
import { RiFacebookFill, RiTwitterFill, RiGithubFill } from 'react-icons/ri'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

const SocialLinks = ({ styleClass }) => {
  const { github, facebook, twitter } = useSiteMetadata()

  return (
    <Wrapper className={styleClass}>
      <li>
        <a href={`https://github.com/${github}`}>
          <RiGithubFill
            className="social-icon github-icon"
            style={{ color: 'grey' }}
          />
        </a>
      </li>
      <li>
        <a href={`https://facebook.com/${facebook}`}>
          <RiFacebookFill
            className="social-icon facebook-icon"
            style={{ color: '#3b5998' }}
          />
        </a>
      </li>
      <li>
        <a href={`https://twitter.com/${twitter}`}>
          <RiTwitterFill
            className="social-icon twitter-icon"
            style={{ color: '#00acee' }}
          />
        </a>
      </li>
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90px;
  margin: 0 auto;
  padding-bottom: 0.5rem;
  li {
    a {
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      color: var(--clr-content-semiemphasis);
      transition: var(--transition);
      .social-icon.facebook-icon,
      .social-icon.twitter-icon,
      .social-icon.github-icon {
        font-size: 1.5rem;
      }
    }
  }
`
export default SocialLinks
