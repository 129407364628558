import React from 'react'
import { Adsense, Title } from '../components'
import CategoriesPosts from './Banner/CategoriesPosts'
import styled from 'styled-components'

const SectionRoadmap = ({ title }) => {
  return (
    <Wrapper>
      <Title title={title || 'posts'} />
      <div className="post-section">
        <CategoriesPosts />
        <Adsense type="display-home" />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 85vw;
  max-width: var(--max-width);
  margin: 0 auto;
  .post-section {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 750px) {
    & {
      .post-section {
        display: grid;
        grid-template-columns: 1fr 300px;
        column-gap: 4rem;
      }
    }
  }
`

export default SectionRoadmap
