import React from 'react'
import { HiOutlineLightBulb } from 'react-icons/hi'
import styled from 'styled-components'

const DefinitionList = ({ children }) => {
  return (
    <Wrapper>
      <div className="container info">
        <HiOutlineLightBulb className="icon" />
        {children}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.dl`
  .container {
    padding: 1rem 1.5rem;
    background: var(--clr-border);
    border-radius: var(--radius);
    color: var(--clr-content);
    border-left: 3px solid var(--clr-content);
    position: relative;
    margin: 2rem 0;
    font-size: 1.125rem;
  }
  @media (min-width: 1170px) {
    .container {
      margin-left: -2rem;
      margin-right: -2rem;
    }
  }
  .icon {
    position: absolute;
    top: 0;
    left: -3px;
    background: var(--clr-white);
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 6px solid var(--clr-white);
  }
  .info {
    background: var(--clr-base-hover-and-background-light);
    border-color: var(--clr-base);
    width: 85vw;
    margin: 2.5rem auto;
    max-width: var(--fixed-width);
    .icon {
      color: var(--clr-base);
    }
  }
`

export default DefinitionList
