import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Title from './Title'
import translateTitle from '../../utils/translateTitle'

const query = graphql`
  query allPagesAndViews {
    allPosts: allMdx(
      limit: 1000
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        frontmatter {
          category
          title
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        id
      }
    }
    allPageViews: allTotalPageViews(
      limit: 5
      sort: { fields: count, order: DESC }
    ) {
      edges {
        node {
          id
          count
        }
      }
    }
  }
`

function chooseTop10(allPosts, popularPosts) {
  const results = []
  for (const a of popularPosts) {
    const selectedPost = allPosts.find(
      b => `/blog/${b.frontmatter.slug}/` === a.node.id.split('@')[1]
    )
    if (selectedPost === null) {
      continue
    } else {
      results.push({
        count: a.node.count,
        ...selectedPost,
      })
    }
    if (results.length >= 10) {
      break
    }
  }
  return results
}

const PopularPost = () => {
  const {
    allPosts: { nodes: posts },
    allPageViews: { edges: popularPosts },
  } = useStaticQuery(query)

  const totalResults = chooseTop10(posts, popularPosts)

  return (
    <Wrapper>
      <Title title="인기글" />
      {totalResults.map(post => {
        const { category, title, slug, featuredImage } = post.frontmatter
        return (
          <Link to={`/blog/${slug}/`} key={post.id} className="post">
            <GatsbyImage
              image={getImage(featuredImage)}
              alt={title}
              className="img"
            ></GatsbyImage>
            <div>
              <h5
                style={{
                  fontWeight: 500,
                  color: 'var(--clr-content-semiemphasis)',
                  radius: 'var(--radius)',
                  paddingBottom: '0.1rem'
                }}
              >
                {translateTitle(category)}
              </h5>
              <h5>{title}</h5>
            </div>
          </Link>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .post {
    display: grid;
    grid-template-columns: 75px 1fr;
    column-gap: 0.5rem;
    margin-bottom: 1rem;
    margin-left: 0.75rem;
  }
  .img {
    border-radius: var(--radius);
    height: 50px;
    width: 75px;
  }
  h5 {
    font-size: 0.7rem;
    letter-spacing: 0;
    line-height: 1.2;
    color: var(--clr-content);
  }
  .post:hover {
    h5 {
      color: var(--clr-content-semiemphasis);
    }
  }
`

export default PopularPost
