import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'

const SubBookbar = ({ currentTitle }) => {
  const {
    allMdx: { nodes },
  } = useStaticQuery(query)

  // currentTitle.includes('-')? currentTitle.split('-')[0].slice(0, -3)

  const subBookbarTitle = currentTitle.includes('-')
    ? null
    : null
  const filteredData = nodes.filter(node =>
    node.frontmatter.title.includes(`${subBookbarTitle}`)
  )

  return subBookbarTitle ? (
    <>
      <Wrapper>
        <span className="sub-bookbar-main-title" style={{ fontWeight: '600' }}>
          {subBookbarTitle}
        </span>
        <>
          {filteredData.map(node => {
            const title = node.frontmatter.title
            const slug = node.frontmatter.slug

            return (
              <li key={`sub-bookbar-title-${title}`}>
                <Link
                  to={`/blog/${slug}/`}
                  key={`sub-bookbar-title-${title}`}
                  className="link"
                >
                  {title.split('-')[1]}
                </Link>
              </li>
            )
          })}
        </>
      </Wrapper>
    </>
  ) : null
}

export const query = graphql`
  query TitleQuery {
    allMdx(
      sort: { fields: [frontmatter___date], order: ASC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        frontmatter {
          title
          division
          category
          subcategory
          slug
        }
      }
    }
  }
`
const Wrapper = styled.aside`
  min-width: 150px;
  max-width: 167px;
  max-height: 85vh;
  border: 1px solid var(--clr-hover-border-background-dark);
  border-radius: var(--radius);
  padding: 0.5rem;
  font-size: 13px;
  font-weight: 300;
  position: absolute;
  top: 0;
  overflow: hidden;
  z-index: 999;
  li {
    list-style-type: none;
    .link {
      padding: 0.25rem 0.25rem 0.25rem 0.5rem;
      text-decoration: none;
      color: var(--clr-primary-text-color);
    }
    .link:hover {
      background: var(--clr-hover-border-background);
      border-radius: var(--radius);
    }
  }
`
export default SubBookbar
