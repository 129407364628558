import React from 'react'
import styled from 'styled-components'
import { Adsense } from '../../components'

const StyledH2 = props => {
  return (
    <>
      {/* <AdsenseWrapper>
        <Adsense type="display-in-article-left" />
        <Adsense type="display-in-article-right" />
      </AdsenseWrapper> */}
      <Adsense type="in-article" />
      <CustomedHeadingH2 {...props}>
        {props.children}
        <HeadingTwo>
          <div className="underline"></div>
        </HeadingTwo>
      </CustomedHeadingH2>
    </>
  )
}

/*
const AdsenseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  .square {
    width: 336px;
    height: 280px;
    margin-left: 0.75px;
    margin-right: 0.75px;
  }
  @media (min-width: 795px) {
    flex-direction: row;
  }
  .pc-only {
    display: none;
    @media (min-width: 795px) {
      display: inline-block;
    }
  }
`
*/

const CustomedHeadingH2 = styled.h2`
  margin: 2rem auto 2.5rem auto;
  color: var(--clr-content);
  max-width: var(--fixed-width);
  a {
    float: left;
    padding-right: 4px;
    margin-left: -20px;
  }
  svg {
    visibility: hidden;
  }
  &:hover {
    a {
      svg {
        visibility: visible;
      }
    }
  }
  @media (min-width: 992px) {
    & {
      width: 92vw;
    }
  }
`

const HeadingTwo = styled.div`
  margin: 1.5rem 0;
  .underline {
    width: 50px;
    height: 5px;
    background: var(--clr-base);
    opacity: 0.9;
  }
`
const StyledH3 = props => {
  return <CustomHeadingH3 {...props}>{props.children}</CustomHeadingH3>
}

const CustomHeadingH3 = styled.h3`
  margin: 2rem auto;
  background: var(--clr-hyperlink-dark);
  color: var(--clr-white);
  border-radius: var(--radius);
  padding: 0.75rem 1rem;
  max-width: var(--fixed-width);
  line-height: 1.25;
  a {
    float: left;
    padding-right: 4px;
    margin-left: -20px;
  }
  svg {
    visibility: hidden;
  }
  &:hover {
    a {
      svg {
        visibility: visible;
      }
    }
  }
`

const StyledH4 = props => {
  return <CustomHeadingH4 {...props}>{props.children}</CustomHeadingH4>
}

const CustomHeadingH4 = styled.h4`
  margin: 1.25rem 0;
  border-radius: var(--radius);
  color: var(--clr-hyperlink);
  max-width: var(--fixed-width);
  line-height: 1.25;
  a {
    float: left;
    padding-right: 4px;
    margin-left: -20px;
  }
  svg {
    visibility: hidden;
  }
  &:hover {
    a {
      svg {
        visibility: visible;
      }
    }
  }
`

const StyledH5 = props => {
  return <CustomHeadingH5 {...props}>{props.children}</CustomHeadingH5>
}

const CustomHeadingH5 = styled.h5`
  margin: 0.875rem 0;
  color: var(--clr-content-semiemphasis);
  border-radius: var(--radius);
  max-width: var(--fixed-width);
  font-size: 1.125rem;
  line-height: 1.25;
  a {
    float: left;
    padding-right: 4px;
    margin-left: -20px;
  }
  svg {
    visibility: hidden;
  }
  &:hover {
    a {
      svg {
        visibility: visible;
      }
    }
  }
`

export { StyledH2, StyledH3, StyledH4, StyledH5 }
