import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Title from './Title'

const query = graphql`
  {
    allMdx(
      limit: 5
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { division: { eq: "project" }, published: { eq: true } }
      }
    ) {
      nodes {
        frontmatter {
          title
          date(formatString: "YYYY-MM-DD")
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        id
      }
    }
  }
`

const RecentProject = () => {
  const data = useStaticQuery(query)

  const {
    allMdx: { nodes: projects },
  } = data

  return (
    <Wrapper>
      <Title title="최근 프로젝트" />
      {projects && projects.map(project => {
        const {
          title,
          slug,
          date,
          featuredImage,
        } = project.frontmatter
        return (
          <Link
            to={`/blog/${slug}/`}
            key={project.id}
            className="project"
          >
            <GatsbyImage
              image={getImage(featuredImage)}
              alt={title}
              className="img"
            ></GatsbyImage>
            <div>
              <h5>{title}</h5>
              <p>{date}</p>
            </div>
          </Link>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .project {
    display: grid;
    grid-template-columns: 75px 1fr;
    column-gap: 0.5rem;
    margin-bottom: 1rem;
    margin-left: 0.75rem;
  }
  .img {
    border-radius: var(--radius);
    height: 50px;
    width: 75px;
  }
  h5 {
    font-size: 0.7rem;
    letter-spacing: 0;
    line-height: 1.2;
    color: var(--clr-content);
  }
  p {
    font-size: 0.6rem;
    margin-bottom: 0;
    color: var(--clr-content-semiemphasis);
  }
  .project:hover {
    h5 {
      color: var(--clr-content-semiemphasis);
    }
  }
`

export default RecentProject
