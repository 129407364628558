const translateTitle = title => {
  switch (title) {
    case 'computer science':
      return '컴퓨터 과학'
    case 'basic':
      return '기초'
    case 'data science':
      return '데이터 과학'
    case 'statistics':
      return '통계'
    case 'mathematics':
      return '수학'
    case 'linear algebra':
      return '선형대수학'
    case 'machine learning':
      return '머신러닝'
    case 'data collection':
      return '데이터 수집'
    case 'data preprocessing':
      return '데이터 전처리'
    case 'visualization':
      return '데이터 시각화'
    case 'environment setup':
      return '환경 설정'
    case 'development':
      return '개발'
    case 'natural language':
      return '자연어'
    case 'english':
      return '영어'
    case 'korean':
      return '한국어'
    case 'programming language':
      return '프로그래밍 언어'
    case 'c':
      return 'C'
    case 'kotlin':
      return 'Kotlin'
    case 'html':
      return 'HTML'
    case 'react':
      return 'React'
    case 'web development':
      return '웹 개발'
    case 'web app':
      return '웹 앱'
    case 'dashboard':
      return '대시보드'
    default:
      return null
  }
}

export default translateTitle