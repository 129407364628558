import React from 'react'
import styled from 'styled-components'
const Title = ({ title, off }) => {
  return (
    <Wrapper>
      <h4>{title}</h4>
      {!off && <div className="line"></div>}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  position: relative;
  text-align: start;
  margin-top: 1rem;
  margin-bottom: 1rem;
  h4 {
    color: var(--clr-content-emphasis);
    text-transform: uppercase;
    font-weight: 800;
    background: var(--clr-white);
    display: inline-block;
    margin-bottom: 0;
    padding: 0 0.6rem;
  }
  .line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1.5px;
    transform: translateY(-50%);
    background: var(--clr-base);
    z-index: -1;
  }
`
export default Title
