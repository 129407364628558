import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import {
  Blockquote,
  Cite,
  Code,
  DefinitionList,
  DefiningTerm,
  DescriptionDetails,
  Footnote,
  Headings,
  InlineQuote,
  Mark,
  PrismSetup
} from './src/components/Complete'

const components = {
  blockquote: Blockquote,
  cite: Cite,
  dl: DefinitionList,
  dt: DefiningTerm,
  dd: DescriptionDetails,
  h2: Headings.StyledH2,
  h3: Headings.StyledH3,
  h4: Headings.StyledH4,
  h5: Headings.StyledH5,
  inlineCode: Code,
  q: InlineQuote,
  mark: Mark,
  pre: PrismSetup,
  sup: Footnote
}
export const wrapMDX = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>
}
