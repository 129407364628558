import React from 'react'

const Mark = ({ children }) => {
  return (
    <mark
      style={{
        background: 'var(--clr-eye-friendly)',
        borderRadius: 'var(--radius)',
        padding: '0 0.25rem'
      }}
    >
      {children}
    </mark>
  )
}

export default Mark
