import React from 'react'

const Cite = ({ children }) => {
  return (
    <cite
      style={{
        fontStyle: 'normal'
      }}
    >
      {children}
    </cite>
  )
}

export default Cite