import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CaptionedImage, MathBlock, TwoColumns, ThreeColumns } from "../../../../src/components";
import * as React from 'react';
export default {
  Link,
  GatsbyImage,
  getImage,
  CaptionedImage,
  MathBlock,
  TwoColumns,
  ThreeColumns,
  React
};