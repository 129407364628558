import React from 'react'
import styled from 'styled-components'
import 'katex/dist/katex.min.css'
import TeX from '@matejmazur/react-katex'
import { GatsbyImage } from 'gatsby-plugin-image'

const MathBlock = ({
  term,
  term_english,
  symbol,
  keyword,
  definition,
  image_src,
  image_alt,
  image_srcName,
  image_srcUrl,
  summary_statistic,
  purposes,
  features,
  type,
  examples,
  prerequisites,
  prerequisites_formula,
  formula,
  variables,
  synonyms,
  section
}) => {
  return (
    <Wrapper section={section}>
      <div className="math-header">
        <div className="terms">
          <span className="term">{term}</span>
          {term_english && <span>{term_english}</span>}
          {symbol && (
            <>
              {term_english && <span>,</span>}
              <span className="symbol">
                <TeX>{symbol}</TeX>
              </span>
            </>
          )}
        </div>
        {keyword && <span className="keyword">{keyword}</span>}
      </div>
      <div className="math-body">
        {definition && <p className="definition">{definition}</p>}
        {purposes && (
          <div className="purposes">
            <span className="purposes-header">목적</span>
            <ul className="purposes-body">
              {purposes.split('@').map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        )}
        {prerequisites && (
          <div className="prerequisites">
            <span className="prerequisites-header">전제</span>
            <ul className="prerequisites-body">
              {prerequisites.split('@').map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        )}
        {prerequisites_formula && (
          <div className="prerequisites-formula">
            <span className="prerequisites-formula-header">가정</span>
            <ul className="prerequisites-formula-body">
              {prerequisites_formula.split('@').map(item => (
                <li key={item}>
                  <TeX>{item}</TeX>
                </li>
              ))}
            </ul>
          </div>
        )}
        {formula && (
          <div className="formula-variables">
            <span className="formula-header">공식</span>
            <div className="formula-body">
              <ul className="formula">
                {formula.split('@').map(item => (
                  <li key={item}>
                    <TeX>{item}</TeX>
                  </li>
                ))}
              </ul>
              {variables && (
                <ul className="variables">
                  {variables.split('@').map(item => (
                    <li key={item}>
                      <TeX>{item.split(':')[0]}</TeX>: {item.split(':')[1]}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
        {image_src && image_alt && (
          <div className="image">
            <span className="image-header">그림</span>
            <figure className="image-body">
              <GatsbyImage
                alt={image_alt}
                image={image_src}
                className="inline-img"
              />
              <figcaption
                className={image_srcName}
                style={{
                  textAlign: 'right',
                  marginTop: '0.625rem',
                  marginRight: '0.25rem',
                  display: 'block',
                  fontStyle: 'normal',
                  fontSize: '0.875rem',
                  fontWeight: 300,
                  color: 'var(--clr-caption-text-color)',
                }}
              >
                {
                  <>
                    <span>출처: </span>
                    {image_srcUrl ? (
                      <a
                        href={image_srcUrl}
                        style={{ color: 'var(--clr-hyperlink)' }}
                      >
                        {image_srcName}
                      </a>
                    ) : (
                      image_srcName
                    )}
                  </>
                }
              </figcaption>
            </figure>
          </div>
        )}
        {summary_statistic && (
          <div className="summary-statistic">
            <span className="summary-statistic-header">성질</span>
            <ul className="summary-statistic-body">
              {summary_statistic.split('@').map(item => (
                <li key={item}>
                  <TeX>{item}</TeX>
                </li>
              ))}
            </ul>
          </div>
        )}
        {features && (
          <div className="features">
            <span className="features-header">설명</span>
            <ul className="features-body">
              {features.split('@').map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        )}
        {type && (
          <div className="type">
            <span className="type-header">종류</span>
            <ul className="type-body">
              {type.split('@').map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        )}
        {examples && (
          <div className="examples">
            <span className="examples-header">예시</span>
            <ul className="examples-body">
              {examples.split('@').map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        )}
        {synonyms && <span className="synonyms">= {synonyms}</span>}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
  padding: 1rem 1.25rem;
  background-color: ${props =>
    props.section === 'Support'
      ? 'var(--clr-hover-border-background-dark)'
      : 'var(--clr-base-hover-and-background-light)'};
  border-radius: var(--radius);
  .math-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .math-body {
    display: flex;
    flex-direction: column;
  }
  .term {
    display: flex;
    align-items: center;
    font-size: 19px;
    font-weight: bolder;
  }
  .symbol {
    margin-left: 0.5rem;
    font-size: 16px;
    .base {
      margin: 0 auto;
    }
  }
  .keyword {
    background-color: var(--clr-base);
    color: var(--clr-white);
    border-radius: var(--radius);
    padding: 0.25rem 0.5rem;
    margin-bottom: 1.25rem;
  }
  .definition {
    font-size: 17px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .prerequisites-formula-header,
  .image-header,
  .summary-statistic-header,
  .purposes-header,
  .features-header,
  .type-header,
  .examples-header,
  .prerequisites-header,
  .formula-header {
    position: relative;
    top: 1.125rem;
    left: -0.625rem;
    background-color: var(--clr-base-2);
    color: var(--clr-white);
    border-radius: var(--radius);
    padding: 0.25rem 0.5rem;
  }
  .summary-statistic-body,
  .image-body,
  .purposes-body,
  .features-body,
  .type-body,
  .examples-body,
  .prerequisites-body,
  .prerequisites-formula-body {
    background-color: var(--clr-white);
    border-radius: var(--radius);
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 0.75rem 0;
    display: flex;
    flex-direction: column;
    list-style-type: circle;
    li {
      margin-left: 1.25rem;
    }
  }
  .summary-statistic-body,
  .prerequisites-formula-body {
    line-height: 2.5;
  }
  .formula-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-white);
    border-radius: var(--radius);
    padding: 1.75rem 1rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    margin-left: 0;
    .formula {
      text-align: center;
      margin-left: 0;
      margin-bottom: 0;
      span {
        line-height: 3;
      }
    }
    .variables {
      font-size: 13px;
      margin-top: 1.5rem;
      margin-bottom: 0;
      line-height: 1.2;
    }
  }
  .summary-statistics {
  }
  .synonyms {
    font-weight: 200;
    display: flex;
    justify-content: flex-end;
  }
`

export default MathBlock
