import React from 'react'
import styled from 'styled-components'
import { GrAnnounce } from 'react-icons/gr'
import earthquakeArchiveLogo from '../../static/earthquake-archive-logo.png'

const Announcement = () => {
  return (
    <Wrapper>
      <p>
        <GrAnnounce className="icon" />
        &nbsp;&nbsp;<span className="badge">new</span>&nbsp;&nbsp;
      </p>
      <p>한국과 전 세계의 지진 현황이 궁금하시다면? &nbsp;</p>
      <p>
        <strong>지진 실시간 상황판</strong>&nbsp;
      </p>
      <p>
        <img src={earthquakeArchiveLogo} alt="earthquake-archive-logo" />
        &nbsp;&nbsp;
        <a href="https://earthquakearchive.kr">https://earthquakearchive.kr</a>
      </p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: var(--clr-announcement);
  padding: 13px 16px;
  line-height: 23px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  .badge {
    background-color: var(--clr-condition);
    border-radius: 5px;
    font-size: 15px;
    color: var(--clr-white);
    padding: 0.5px 4px;
  }
  .icon {
    height: 26px;
  }
  img {
    height: 20px;
  }
  a {
    color: var(--clr-hyperlink);
    font-weight: bold;
    border-bottom: 1px solid var(--clr-hyperlink);
  }
  @media (min-width: 460px) {
    p {
      font-size: 18px;
      margin-bottom: 0.15rem;
    }
  }
  @media (min-width: 839px) {
    flex-direction: row;
  }
`

export default Announcement
