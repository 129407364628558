import React from 'react'
import styled from 'styled-components'
import logo from '../../static/logo.png'
import { Link } from 'gatsby'
import { FaBars } from 'react-icons/fa'
import NavLinks from '../constants/navLinks'

const Navbar = ({ toggle }) => {
  return (
    <Wrapper>
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/" className="nav-logo">
            <img src={logo} alt="mdx logo" />
          </Link>
          <button className="toggle-btn" onClick={toggle}>
            <FaBars />
          </button>
        </div>
        <NavLinks styleClass="nav-links" />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  background: var(--clr-base);
  z-index: 1;
  height: 5rem;
  .nav-center {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
  }
  .nav-header {
    color: var(--clr-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-logo {
      display: flex;
      justify-content: start;
      font-size: 2rem;
      font-weight: 700;
      padding-top: 0.25rem;
      color: var(--clr-white);
      letter-spacing: 0.025rem;
      &:hover {
        opacity: 0.7;
      }
    }
    .nav-logo img {
      margin-left: -1rem;
    }
    .toggle-btn {
      width: 3.5rem;
      height: 2.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.675rem;
      background: transparent;
      border: transparent;
      color: var(--clr-white);
      cursor: pointer;
      transition: var(--transition);
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  .nav-links {
    display: none;
  }
  @media screen and (min-width: 700px) {
    .nav-header {
      .toggle-btn {
        display: none;
      }
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      column-gap: 2rem;
      align-items: center;
    }
    .nav-links {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 1rem;
      z-index: 999;
    }
    .nav-icons {
      margin: 0 auto;
    }
    button {
      color: var(--clr-white);
      background: transparent;
      border: transparent;
      font-size: 1rem;
      letter-spacing: 2px;
      font-weight: 500;
      padding: 10px 20px;
      width: 100%;
      text-transform: capitalize;
      position: relative;
    }
  }
`

export default Navbar
