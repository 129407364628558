import React from 'react'
import styled from 'styled-components'
import About from './About'
import BannerCategoriesTags from './BannerCategoriesTags'
import CategoriesPosts from './CategoriesPosts'
import CategoriesProjects from './CategoriesProjects'
import RecentPost from './RecentPost'
import RecentProject from './RecentProject'
import PopularPost from './PopularPost'

const Banner = () => {
  return (
    <Wrapper>
      <About introduction="웹 개발, 데이터 분석" />
      <CategoriesPosts bannerTitle="글" />
      <CategoriesProjects bannerTitle="프로젝트" />
      <RecentProject />
      <RecentPost />
      <PopularPost />
      <BannerCategoriesTags />
    </Wrapper>
  )
}

const Wrapper = styled.aside`
  display: grid;
  grid-template-columns: 300px;
  justify-content: center;
  row-gap: 1rem;
  height: min-content;
  @media (min-width: 576px) {
    & {
      grid-template-columns: repeat(auto-fit, 300px);
      grid-auto-rows: min-content;
      column-gap: 1rem;
    }
  }
`
  
export default Banner
