import React from 'react'
import { LinksList } from '../components'
import { mainLinks } from '../data/data.js'

const navLinks = ({ styleClass }) => {
  return (
    <LinksList
      data={mainLinks}
      styleClass={styleClass}
      linkClassName="page-link"
    />
  )
}

export default navLinks
