import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import EachTag from '../EachTag'
import { IoMdArrowRoundForward } from 'react-icons/io'
import { BsCalendarEvent, BsTagsFill } from 'react-icons/bs'
import { CgSandClock } from 'react-icons/cg'
import styled from 'styled-components'
import replaceSpaceWithDash from '../../utils/replaceSpaceWithDash'

const Post = ({ excerpt, frontmatter, timeToRead }) => {
  const {
    category,
    subcategory,
    title,
    subtitle,
    slug,
    date,
    tags,
    featuredImage
  } = frontmatter

  const categoryWithHyphen = replaceSpaceWithDash(category)
  const subcategoryWithHyphen = replaceSpaceWithDash(subcategory)
  return (
    <Wrapper>
      <Link to={`/blog/${slug}/`} className="link">
        <GatsbyImage
          image={getImage(featuredImage)}
          alt={title}
          className="img"
        />
      </Link>
      <div className="info">
        <div className="classification">
          <Link to={`/blog/category/${categoryWithHyphen}/`} className="link">
            <span className="category">{category}</span>
          </Link>
          <Link
            to={`/blog/category/${categoryWithHyphen}/${subcategoryWithHyphen}/`}
            className="link"
          >
            <span className="subcategory">{subcategory}</span>
          </Link>
        </div>
        <Link to={`/blog/${slug}/`} className="link">
          <div className="post-title">
            <h3>{title}</h3>
          </div>
          <h4>{subtitle}</h4>
          <div className="underline"></div>
          <div className="preview">
            <p>{excerpt}</p>
            <span className="more">
              더보기 <IoMdArrowRoundForward />
            </span>
          </div>
        </Link>
        <footer>
          <div className="date-and-minutes">
            <span className="date">
              <BsCalendarEvent className="icon" />
              {date}
            </span>
            <span className="minutes">
              <CgSandClock className="icon" />
              {timeToRead}분
            </span>
          </div>
          <span className="tags">
            <BsTagsFill className="icon" />
            <EachTag tags={tags} />
          </span>
        </footer>
      </div>
    </Wrapper>
  )
}


const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border: 1px solid var(--clr-hover-border-background-dark);
  border-radius: var(--radius);
  padding: 1rem;
  .link {
    letter-spacing: var(--spacing);
    color: var(--clr-base);
    svg {
      margin-left: 0.25rem;
      font-size: 1.2rem;
    }
  }
  .img {
    margin-bottom: 1.25rem;
    border-radius: var(--radius);
    height: 17rem;
  }
  .info {
    text-align: center;
    display: grid;
  }
  .classification {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    row-gap: 0.625rem;
  }
  .category {
    background: var(--clr-base);
    padding: 0.25rem 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: var(--radius);
    letter-spacing: var(--spacing);
    color: var(--clr-white);
    &:hover {
      opacity: 0.7;
    }
  }
  .subcategory {
    margin-left: 0.25rem;
    background: var(--clr-base-secondary);
    padding: 0.25rem 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: var(--radius);
    letter-spacing: var(--spacing);
    line-height: 1.625;
    color: var(--clr-white);
    &:hover {
      opacity: 0.7;
    }
  }
  .post-title {
    font-weight: 700;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    text-transform: initial;
    color: var(--clr-content-emphasis);
    line-height: 1.25;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h4 {
    color: var(--clr-content-semiemphasis);
  }
  .underline {
    width: 5rem;
    height: 1px;
    background: var(--clr-hover-border-background-dark);
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .preview {
    p {
      color: var(--clr-content-semiemphasis);
      &:hover {
        opacity: 0.7;
      }
    }
    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      color: var(--clr-white);
      background: var(--clr-compliment);
      border-radius: var(--radius);
      width: 6rem;
      font-size: 1rem;
      font-weight: 800;
      padding: 0.25rem 0.5rem;
    }
  }
  footer {
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid var(--clr-hover-border-background-dark);
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    color: var(--clr-content-semiemphasis);
    font-size: 0.8rem;
    span {
      text-transform: uppercase;
    }
    & .date {
      display: flex;
      align-items: center;
      & .icon {
        color: var(--clr-content-semiemphasis);
        margin-right: 0.5rem;
      }
    }
    & .tags {
      display: flex;
      align-items: center;
      justify-content: end;
      & .icon {
        color: var(--clr-content-semiemphasis);
        margin-right: 0.25rem;
      }
    }
    & .minutes {
      display: flex;
      align-items: center;
      & .icon {
        color: var(--clr-content-semiemphasis);
        margin-right: 0.25rem;
      }
    }
  }
  @media (min-width: 992px) {
    .img {
      height: 17.5rem;
    }
  }
`

export default Post
