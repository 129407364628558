import React from 'react'
import { Link } from 'gatsby'

const LinksList = ({ styleClass, children, data, linkClassName, external }) => {
  return (
    <ul className={styleClass}>
      {data.map(link => {
        const { id, url, text, icon } = link
        return (
          <li key={id}>
            {!external ? (
              <Link to={url} className={linkClassName}>
                {text && text}
              </Link>
            ) : (
              <a href={url}>{icon && icon}</a>
            )}
          </li>
        )
      })}
      {children}
    </ul>
  )
}

export default LinksList
