import React from 'react'
import { LinksList } from '../components'
import { IoMdClose } from 'react-icons/io'
import { mainLinks } from '../data/data.js'
import styled from 'styled-components'

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <Wrapper className={`sidebar ${isOpen ? 'showSidebar' : ''}`}>
      <button className="close-btn" onClick={toggle}>
        <IoMdClose />
      </button>
      <div className="sidebar-container">
        <LinksList styleClass="sidebar-links" data={mainLinks} />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.aside`
  position: fixed;
  left: 0;
  width: 50%;
  height: 100%;
  background: var(--clr-white);
  transition: var(--transition);
  display: grid;
  align-items: center;
  padding: 0 1.25rem;
  z-index: 999;
  transform: translateX(-100%);
  visibility: hidden;
  .sidebar-container {
    margin: 0 auto;
  }
  .sidebar-links li {
    margin-bottom: 1rem;
    a {
      color: var(--clr-content);
      font-size: 1.5rem;
    }
  }
  .sidebar-links .page-link {
    font-size: 1.125rem;
    display: block;
    color: var(--clr-content-semiemphasis);
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .category {
    color: var(--clr-content-emphasis);
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: capitalize;
    display: block;
    cursor: pointer;
    margin-bottom: 0.375rem;
  }
  .sub-category {
    font-size: 0.875rem;
    text-transform: capitalize;
    padding-left: 1rem;
    display: block;
    cursor: pointer;
    margin: 0 auto;
  }
  .sub-category a {
    color: var(--clr-content-semiemphasis);
  }
  .categories {
    flex-direction: column;
    margin: 1rem 0;
  }
  @media screen and (min-width: 700px) {
    transform: translateX(-100%);
  }
`

export default Sidebar
