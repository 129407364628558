import React from 'react'

const DescriptionDetails = ({ children }) => {
  return (
    <dd
      style={{
        fontSize: 'smaller',
        marginBottom: '0.5rem'
      }}
    >
      {children}
    </dd>
  )
}

export default DescriptionDetails
