import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { CategoryList } from '../../components'

const query = graphql`
  query projectsCategory {
    categoryInfo: allMdx(
      filter: {
        frontmatter: { division: { eq: "project" }, published: { eq: true } }
      }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        distinct(field: frontmatter___subcategory)
      }
    }
    subcategoryInfo: allMdx(
      filter: {
        frontmatter: { division: { eq: "project" }, published: { eq: true } }
      }
    ) {
      group(field: frontmatter___subcategory) {
        fieldValue
        totalCount
        distinct(field: frontmatter___subcategory)
      }
    }
  }
`
const CategoriesProjects = bannerTitle => {
  const {
    categoryInfo: { group },
    subcategoryInfo,
  } = useStaticQuery(query)

  return (
    <CategoryList bannerTitle={bannerTitle} group={group} subcategoryInfo={subcategoryInfo} />
  )
}

CategoriesProjects.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
}

export default CategoriesProjects
